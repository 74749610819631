import axios from "axios";
const { protocol, host } = window.location;
const instance = axios.create({
  baseURL: `${protocol}//${host}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
