<template>
  <v-app>
    <v-app-bar app dark color="primary" height="67px">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">
        InvestirAI Tool
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        label="Status"
        key="search"
        v-model="search"
        :items="[
          'CONTACTED',
          'REGISTERED',
          'SIGNED',
          'RESERVED',
          'NOT SIGNED',
          'EXPIRED',
        ]"
        dense
        outlined
        hide-details
        clearable
        color="white"
        class="mr-2"
        style="max-width: 200px"
      >
        <template v-slot:selection="{ item }">
          <v-chip
            v-if="item"
            small
            :color="
              search === 'CONTACTED'
                ? 'pink'
                : search === 'REGISTERED'
                ? 'green'
                : search === 'SIGNED'
                ? 'blue'
                : search === 'RESERVED'
                ? 'orange'
                : search === 'NOT SIGNED'
                ? 'red'
                : search === 'EXPIRED'
                ? 'red'
                : 'grey'
            "
          >
            {{ item }}
          </v-chip>
        </template>
      </v-select>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="newDomainForm">
            <v-list-item-icon>
              <v-icon>mdi-plus-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Add New Domains</v-list-item-title>
          </v-list-item>
          <v-list-item @click="checkEmail">
            <v-list-item-icon>
              <v-icon>mdi-email-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Check Status</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportCSV">
            <v-list-item-icon>
              <v-icon>mdi-file-export</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Export</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app temporary v-model="drawer">
      <v-list>
        <v-list-item
          @click="
            tab = 0;
            drawer = false;
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            tab = 1;
            drawer = false;
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Domains</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            tab = 2;
            drawer = false;
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-format-list-checks</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tasks</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-tabs-items v-model="tab" :touch="{}">
        <v-tab-item>
          <Home />
        </v-tab-item>
        <v-tab-item>
          <DomainsTable :search="search" />
        </v-tab-item>
        <v-tab-item>
          <TasksTable />
        </v-tab-item>
      </v-tabs-items>
      <v-dialog v-model="dialog" max-width="500px" scrollable>
        <v-card :loading="cardLoading">
          <v-card-title>
            Add New Domains
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent ref="form" v-model="valid" lazy-validation>
              <v-tabs v-model="tabDialog" fixed-tabs>
                <v-tab>Names</v-tab>
                <v-tab>CSV File</v-tab>
                <v-tab-item>
                  <v-container>
                    <v-combobox
                      v-model="newDomains"
                      label="Names"
                      outlined
                      multiple
                      chips
                      hint="Type a domain name and press enter to add"
                      persistent-hint
                    >
                      <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-file-input
                      v-model="file"
                      label="File upload"
                      outlined
                      hint="Import a CSV file with domains to add"
                      persistent-hint
                      accept=".csv"
                    ></v-file-input>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="cardLoading"
              color="blue darken-1"
              text
              @click="save"
            >
              <v-icon left>mdi-content-save</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Home";
import DomainsTable from "./components/Domains";
import TasksTable from "./components/Tasks";
import DomainService from "./services/DomainService";

export default {
  name: "App",

  components: {
    Home,
    DomainsTable,
    TasksTable,
  },

  data: () => ({
    search: "",
    drawer: false,
    loading: false,
    tab: 0,
    tabDialog: 0,
    valid: false,
    file: null,
    dialog: false,
    cardLoading: false,
    newDomains: [],
    csvLoading: false,
  }),
  methods: {
    newDomainForm() {
      this.tab = 0;
      this.$refs.form?.reset();
      this.dialog = true;
    },
    async exportCSV() {
      try {
        this.csvLoading = true;
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent +=
          "Domain,Type,Expires on,Owner,Requested on,Initially activated on,Activated on,Processing from,Created at\n";
        const response = await DomainService.list({
          offset: 0,
          limit: 100000,
          status: this.search,
        });
        response.data.data.forEach((domain) => {
          csvContent += `${domain.name},${domain.registration_status},${domain.expires_on},${domain.registrant},${domain.requested_on},${domain.initially_activated_on},${domain.activated_on},${domain.processing_from},${domain.created_at}\n`;
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "domains.csv");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.csvLoading = false;
      }
    },
    async checkEmail() {
      try {
        this.loading = true;
        const response = await DomainService.checkEmail();
        console.log(response);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async uploadFile() {
      if (!this.file) return;
      try {
        this.loading = true;
        let formData = new FormData();
        formData.append("file", this.file);
        await DomainService.uploadCSV(formData);
      } catch {
        console.error("Error uploading file");
      } finally {
        this.file = null;
        this.loading = false;
      }
    },
    async save() {
      try {
        this.cardLoading = true;
        if (this.tabDialog === 1) {
          await this.uploadFile();
        } else {
          if (!this.$refs.form.validate()) return;
          await DomainService.add({ domains: this.newDomains });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.cardLoading = false;
        this.dialog = false;
      }
    },
  },
};
</script>
