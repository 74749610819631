<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tasks"
      item-key="id"
      :footer-props="{
        'items-per-page-text': '',
        'items-per-page-options': [50, 100, 500, 1000],
      }"
      class="elevation-1"
      height="calc(100vh - 130px)"
    >
      <template v-slot:[`item.state`]="{ item }">
        <v-chip
          :color="
            item.state == 'PROGRESS'
              ? 'primary'
              : item.state == 'FAILURE'
              ? 'error'
              : item.state == 'SUCCESS'
              ? 'success'
              : 'warning'
          "
        >
          {{ item.state }}
        </v-chip>
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <v-progress-linear
          v-if="item.state == 'SUCCESS'"
          :value="100"
          height="30"
        />
        <v-progress-linear
          v-else-if="item.state == 'FAILURE'"
          :value="0"
          height="30"
        />
        <v-progress-linear v-else :value="progress[item.id]" height="30" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="error" @click="deleteTask(item)">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TaskService from "../services/TaskService";

export default {
  name: "TasksTable",
  data() {
    return {
      tasks: [],
      progress: {},
      headers: [
        // { text: "ID", value: "id" },
        { text: "Type", value: "type", width: "100px" },
        { text: "Status", value: "state" },
        { text: "Result", value: "result" },
        { text: "Progress", value: "progress", width: "500px" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  async created() {
    this.fetchTasks();
    setInterval(this.fetchTasks, 15000);
  },
  methods: {
    getProgress(item) {
      try {
        const correctedJSONString = item.info.replace(/'/g, '"');
        const { current, total } = JSON.parse(correctedJSONString);
        this.$set(this.progress, item.id, (100 * current) / total);
      } catch {
        console.log("No progress");
      }
    },
    async deleteTask(item) {
      try {
        await TaskService.delete(item.id);
        await this.fetchTasks();
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTasks() {
      try {
        const response = await TaskService.list();
        this.tasks = response.data.data;
        this.tasks.forEach((item) => {
          this.getProgress(item);
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
