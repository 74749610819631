<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title>Home</v-card-title>
          <v-card-text>
            <p>
              Welcome to the Home page. This is a simple Vue.js application that
              demonstrates how to use Vuetify components.
            </p>
            <p>
              The Home page is the default page that is displayed when you first
              open the application.
            </p>
            <v-divider class="my-5" />
            <h2>Statistics</h2>
            <p>This application has the following statistics:</p>
            <ul>
              <li>Total number of domains: 0</li>
              <li>Total number of tasks: 0</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style></style>
