var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tasks,"item-key":"id","footer-props":{
      'items-per-page-text': '',
      'items-per-page-options': [50, 100, 500, 1000],
    },"height":"calc(100vh - 130px)"},scopedSlots:_vm._u([{key:`item.state`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.state == 'PROGRESS'
            ? 'primary'
            : item.state == 'FAILURE'
            ? 'error'
            : item.state == 'SUCCESS'
            ? 'success'
            : 'warning'}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:`item.progress`,fn:function({ item }){return [(item.state == 'SUCCESS')?_c('v-progress-linear',{attrs:{"value":100,"height":"30"}}):(item.state == 'FAILURE')?_c('v-progress-linear',{attrs:{"value":0,"height":"30"}}):_c('v-progress-linear',{attrs:{"value":_vm.progress[item.id],"height":"30"}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteTask(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }