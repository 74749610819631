<template>
  <div>
    <v-data-table
      ref="table"
      fixed-header
      v-model="selectedDomains"
      :items="domains"
      :headers="headers"
      :loading="loading"
      :search="search"
      :footer-props="{
        'items-per-page-text': '',
        'items-per-page-options': [50, 100, 500, 1000],
      }"
      height="calc(100vh - 130px)"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :server-items-length="options.totalItems"
      mobile-breakpoint="0"
      show-select
      multi-sort
      @click:row="openDialog"
    >
      <template v-slot:[`body.prepend`]>
        <tr
          class="filteringRow"
          :style="`background-color : ${
            $vuetify.theme.dark ? '#1e1e1e' : 'white'
          }`"
        >
          <td>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  :readonly="selectedDomains.length === 0"
                  :color="selectedDomains.length > 0 ? 'primary' : ''"
                >
                  {{ selectedDomains.length }}
                </v-chip>
              </template>
              <v-list>
                <v-list-item @click="checkSelectedRows">
                  <v-icon left color="primary"> mdi-eye </v-icon>
                  Check
                </v-list-item>
                <v-list-item @click="selectedDomains = []">
                  <v-icon left> mdi-close </v-icon>
                  Deselect
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td v-for="header in headers" :key="header.value" class="text-center">
            <component
              v-if="header.component"
              v-model="searchBy[header.value]"
              :is="header.component"
              :items="filters[header.value]"
              :style="`min-width: ${header.width}`"
              item-text="text"
              no-data-text="No filters"
              ref="filter"
              :append-icon="searchBy[header.value] ? 'mdi-close' : undefined"
              @click:append="searchBy[header.value] = undefined"
              hide-details
              solo
              dense
            />
          </td>
        </tr>
      </template>
      <template v-slot:[`item.ahrefs`]="{ item }">
        <v-btn
          :href="`https://ahrefs.com/backlink-checker/?input=${item.name}.bg&mode=subdomains`"
          target="_blank"
          @click.stop
          icon
          small
          color="primary"
        >
          <v-icon>mdi-link-variant-plus</v-icon>
        </v-btn>
        <v-btn
          :href="`https://web.archive.org/web/20240000000000*/${item.name}.bg`"
          target="_blank"
          @click.stop
          icon
          small
          color="primary"
        >
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" scrollable>
      <v-card :loading="cardLoading">
        <v-card-title>
          Domain Details
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.name"
                  label="Domain"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.registration_status"
                  label="Type"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.registrant"
                  label="Registrant"
                  outlined
                  readonly
                  hide-details
                  dense
                  :rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.activated_on"
                  label="Activated on"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.expires_on"
                  label="Expires on"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.requested_on"
                  label="Requested on"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.processing_from"
                  label="Processing from"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.initially_activated_on"
                  label="Initially activated on"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="item.created_at"
                  label="Created at"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.dnssec"
                  label="DNSSEC"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.name_server_information"
                  label="Name server information"
                  outlined
                  readonly
                  hide-details
                  dense
                  :rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.administrative_contact"
                  label="Administrative contact"
                  outlined
                  readonly
                  hide-details
                  dense
                  :rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.technical_contact"
                  label="Technical contact"
                  outlined
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.note"
                  label="Note"
                  outlined
                  readonly
                  hide-details
                  dense
                  :rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-toolbar dark flat dense class="mt-4">
                <v-toolbar-title>History</v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <v-data-table
                  :headers="headers.slice(1)"
                  :items="item.infos"
                  :items-per-page="-1"
                  hide-default-footer
                />
              </v-col>
            </v-row>
            <v-row>
              <v-toolbar dark flat dense class="mt-4">
                <v-toolbar-title>Statuses</v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <v-data-table
                  :headers="ownerHeaders"
                  :items="item.statuses"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="getColor(item.status)">
                      <v-icon class="mr-2" v-if="item.status === 'CONTACTED'">
                        mdi-email
                      </v-icon>
                      <v-icon
                        class="mr-2"
                        v-else-if="item.status === 'REGISTERED'"
                      >
                        mdi-certificate
                      </v-icon>
                      <v-icon class="mr-2" v-else-if="item.status === 'SIGNED'">
                        mdi-check
                      </v-icon>
                      <v-icon
                        class="mr-2"
                        v-else-if="item.status === 'WARNING'"
                      >
                        mdi-alert
                      </v-icon>
                      <v-icon
                        class="mr-2"
                        v-else-if="item.status === 'RESERVED'"
                      >
                        mdi-information
                      </v-icon>
                      <v-icon
                        class="mr-2"
                        v-else-if="item.status === 'NOT SIGNED'"
                      >
                        mdi-close
                      </v-icon>
                      {{ item.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VTextField, VSelect } from "vuetify/lib";
import DomainService from "@/services/DomainService";

export default {
  name: "DomainsTable",
  props: {
    search: String,
    tab: Number,
  },
  components: {
    VTextField,
    VSelect,
  },
  data: () => ({
    dialog: false,
    options: {
      page: 1,
      itemsPerPage: 100,
      totalItems: 0,
    },
    optionsTimeout: null,
    searchTimeout: null,
    init: true,
    selectedDomains: [],
    item: {},
    searchBy: {},
    loading: false,
    cardLoading: false,
    domains: [],
    ownerHeaders: [
      {
        text: "Status",
        value: "status",
        component: "v-text-field",
      },
      {
        text: "Email",
        value: "email",
        component: "v-text-field",
      },
      {
        text: "Date",
        value: "domain_date",
        component: "v-text-field",
      },
    ],
    headers: [
      {
        text: "Domain",
        value: "name",
        component: "v-text-field",
        width: "100px",
      },
      {
        text: "Type",
        value: "registration_status",
        component: "v-text-field",
        width: "100px",
      },
      {
        text: "Expires on",
        value: "expires_on",
        component: "v-text-field",
        width: "150px",
      },
      {
        text: "Owner",
        value: "registrant",
        component: "v-text-field",
        width: "200px",
      },
      {
        text: "Requested on",
        value: "requested_on",
        component: "v-text-field",
        width: "150px",
      },
      {
        text: "Initially activated on",
        value: "initially_activated_on",
        component: "v-text-field",
        width: "200px",
      },
      {
        text: "Activated on",
        value: "activated_on",
        component: "v-text-field",
        width: "150px",
      },
      {
        text: "Processing from",
        value: "processing_from",
        component: "v-text-field",
        width: "150px",
      },
      {
        text: "Created at",
        value: "created_at",
        component: "v-text-field",
        width: "150px",
      },
      {
        text: "AHREFS",
        value: "ahrefs",
      },
    ],
  }),
  mounted() {
    this.fetchDomains();
    // setInterval(this.fetchDomains, 5000);
  },
  computed: {
    filters() {
      return {
        status: [
          { text: "CONTACTED", value: "CONTACTED" },
          { text: "REGISTERED", value: "REGISTERED" },
          { text: "SIGNED", value: "SIGNED" },
          { text: "NOT SIGNED", value: "NOT SIGNED" },
          { text: "RESERVED", value: "RESERVED" },
          { text: "WARNING", value: "WARNING" },
          { text: "UNKNOWN", value: "" },
        ],
        registration_status: [
          { text: "available", value: "available" },
          { text: "busy, active", value: "busy, active" },
          { text: "busy, inactive", value: "busy, inactive" },
          { text: "UNKNOWN", value: "" },
        ],
      };
    },
  },
  methods: {
    getColor(status) {
      switch (status) {
        case "CONTACTED":
          return "pink";
        case "REGISTERED":
          return "success";
        case "SIGNED":
          return "primary";
        case "WARNING":
          return "warning";
        case "RESERVED":
          return "info";
        case "NOT SIGNED":
          return "error";
        default:
          return "error";
      }
    },
    async checkSelectedRows() {
      try {
        this.loading = true;
        await DomainService.add({
          domains: this.selectedDomains.map((domain) => domain.name),
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.selectedDomains = [];
      }
    },
    async fetchDomains() {
      try {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let sort = {};
        if (sortBy && sortBy.length > 0) {
          sortBy.forEach((item, index) => {
            sort[item] = sortDesc[index] ? "desc" : "asc";
          });
        }
        const response = await DomainService.list({
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          search: this.searchBy,
          sort: sort,
          status: this.search,
        });
        this.domains = response.data.data;
        this.options.totalItems = response.data.total;
      } catch (error) {
        console.error("Error fetching domains:", error);
      }
    },
    openDialog(item) {
      this.item = item;
      this.dialog = true;
    },
  },
  watch: {
    search() {
      this.fetchDomains();
    },
    options: {
      handler() {
        if (this.init) {
          this.init = false;
          return;
        }
        clearTimeout(this.optionsTimeout);
        this.optionsTimeout = setTimeout(() => {
          this.fetchDomains();
        }, 2000);
      },
      deep: true,
    },
    searchBy: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.fetchDomains();
        }, 5000);
      },
      deep: true,
    },
  },
};
</script>
<style>
.filteringRow {
  position: sticky;
  top: 48px;
  z-index: 1;
}
</style>
