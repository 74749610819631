import AxiosInstance from "./AxiosInstance";

export default {
  uploadCSV(body) {
    return AxiosInstance.post("/upload-csv", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  list({ offset, limit, search, sort, status }) {
    return AxiosInstance.post(`/domains/${offset}/${limit}/list`, {
      search,
      sort,
      status,
    });
  },
  add(body) {
    return AxiosInstance.post("/domains/add", body);
  },
  checkEmail() {
    return AxiosInstance.get("/mail");
  },
};
