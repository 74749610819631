import AxiosInstance from "./AxiosInstance";

export default {
  list() {
    return AxiosInstance.get(`/tasks`);
  },
  get(id) {
    return AxiosInstance.get(`/tasks/${id}`);
  },
  delete(id) {
    return AxiosInstance.delete(`/tasks/${id}`);
  },
};
